import * as React from 'react';
import cn from 'classnames';
import { boolean, object, string } from 'yup';
import mapValues from 'lodash-es/valuesIn';
import { postUsersSignIn } from 'api/userAuthentication';
import { Button, Link } from 'styleguide/components';
import { H4 } from 'styleguide/components/Heading';
import FormWrapper from '../FormWrapper/FormWrapper';
import PageLevelError from 'styleguide/components/PageLevelError/PageLevelError';
import { Status } from 'libs/utils/api/types';
import { OperatorResponse } from 'api/types';
import { resetPasswordPath, signUpPath, privacyPath, termsAndConditionsPath } from 'bundles/App/routes';
import Meta from 'styleguide/components/Meta/Meta';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import Loader from 'styleguide/components/Loader/Loader';
import Input from 'styleguide/components/Formik/Input/Input';
import Label from 'styleguide/components/Formik/Label/Label';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import { useHistory } from 'react-router';

interface Props {
  hideRememberMe?: boolean;
  leftAlign?: boolean;
  fullWidthButton?: boolean;
  repositionForgetPassword?: boolean;
  onSuccess?: () => void;
}

const SignInFormSchema = object().shape({
  email: string()
    .strict()
    .email('Please enter a valid email.')
    .trim('Email cannot begin or end with spaces.')
    .required('Email is required.'),
  password: string().ensure().required('Please enter your password.'),
  rememberMe: boolean(),
});

const SignInPage = ({ hideRememberMe, leftAlign, repositionForgetPassword, onSuccess }: Props) => {
  const history = useHistory();
  const userContext = React.useContext(UserContext);

  const signIn = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    const handleSuccess = (response: OperatorResponse) => {
      // TODO: there might be a problem if there will be a transition
      // from another website directly to the sign-in page.
      // After signing-in, there will be a redirect to the
      // previous website.
      // There is no clear way to avoid this:
      // https://github.com/ReactTraining/history/issues/573
      if (history.length > 2) {
        /*
          https://developer.mozilla.org/en-US/docs/Web/API/History/length
          `For example, for a page loaded in a new tab this property returns 1.`
          So, first page is the new tab, second page is the /sign-in
        */
        // TODO: probably update csrf result disappearing after goBack
        history.goBack();
      } else {
        history.push('/');
      }
      userContext.logIn(response.user);
      if (onSuccess) {
        onSuccess();
      }
    };

    postUsersSignIn(values).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        handleSuccess(res.payload);
      }
    });
  };

  return (
    <FormWrapper>
      <Meta
        title="SignInPage"
        description=""
        keywords=""
        robots={['noindex']}
        canonical="https://www.printivity.com/sign-in"
      />
      <H4
        data-cy="signInPageTitle"
        className={cn(
          'text-center !font-hvBold !text-4xl !font-bold -sm:mt-4',
          leftAlign ? '!text-left' : null,
        )}
      >
        Sign In
      </H4>
      <div className="paragraph-desktop mb-9 mt-4 text-gray-500">
        Welcome back! Please enter your details.
      </div>
      <Formik
        initialValues={{ email: '', password: '', rememberMe: true }}
        onSubmit={(formikValues, { setErrors, setStatus, setSubmitting }) => {
          signIn(formikValues, setStatus, setSubmitting, setErrors);
        }}
        validationSchema={SignInFormSchema}
      >
        {formikProps => (
          <>
            {formikProps.status && (
              <>
                <br />
                <PageLevelError message={formikProps.status.title}>
                  {!!formikProps.status.errors &&
                    mapValues(formikProps.status.errors).map((value, index) => (
                      <PageLevelError.Item key={index} value={value} />
                    ))}
                </PageLevelError>
                <br />
              </>
            )}
            <Form>
              <Field
                inPlaceError
                type="email"
                name="email"
                component={Input}
                label="Email address"
                data-cy="email"
              />
              <Field
                inPlaceError
                type="password"
                name="password"
                component={Input}
                label="Password"
                data-cy="password"
              />

              <div
                className={cn(
                  'mb-7 flex items-start justify-between',
                  hideRememberMe ? 'hidden' : null,
                  'mt-1',
                )}
              >
                <div className="flex">
                  <Field
                    name="rememberMe"
                    component={Checkbox}
                    size="sm"
                    className="-mt-1"
                    data-cy="rememberMe"
                  />
                  <Label placement="left" normalFontWeight>
                    Remember Me
                  </Label>
                </div>
                {!repositionForgetPassword && (
                  <Link
                    color="black"
                    underline="always"
                    to={resetPasswordPath}
                    className="!text-xs !leading-4 text-gray-500"
                    data-cy="forgotPassword"
                  >
                    Forgot password?
                  </Link>
                )}
              </div>
              {formikProps.isSubmitting ? (
                <Loader className="mx-auto" />
              ) : (
                <Button
                  className="w-full !px-0"
                  type="button"
                  onClick={() => formikProps.submitForm()}
                  color="blue"
                  data-cy="signInBtn"
                >
                  Sign In
                </Button>
              )}
            </Form>
          </>
        )}
      </Formik>
      <div className="text-center">
        <div className="caption my-4 text-gray-500">or</div>
        <Button.AsNativeLink
          className="w-full !px-0"
          buttonType="link"
          color="dark"
          outline
          target={signUpPath}
          size="sm"
          dataCy="createAccountButton"
        >
          Create An Account
        </Button.AsNativeLink>
      </div>
      <div className="caption mt-9 border-t border-solid border-gray-50 pt-9 text-gray-500 -sm:mb-4">
        By signing in, you are agreeing to our{' '}
        <Link
          to={termsAndConditionsPath}
          color="blue"
          underline="none"
          className="!font-hvRoman !text-xs !font-normal !leading-4"
        >
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link
          to={privacyPath}
          color="blue"
          underline="none"
          className="!font-hvRoman !text-xs !font-normal !leading-4"
        >
          Privacy Policy
        </Link>
        .
      </div>
    </FormWrapper>
  );
};
export default SignInPage;
